<template>
  <v-card
    outlined
    elevation="3"
    class="ticket-block-management"
    :class="{ selected: isSelected }"
    @click="onClick"
    rounded="lg"
  >
    <v-progress-linear
      :color="`blue-grey ${$vuetify.theme.dark ? 'darken-4' : 'lighten-4'}`"
      :background-color="`${progressColor} lighten-1`"
      :value="
        ((ticketBlock.quantity - ticketBlock.tickets.remaining) /
          ticketBlock.quantity) *
        100
      "
      height="20"
      class="drag-handleBlock rounded-t"
    >
      <v-btn icon>
        <v-icon>mdi-drag-horizontal</v-icon>
      </v-btn>
      <div class="d-flex caption ml-2 justify-space-around w-full">
        <b>{{ ticketBlock.quantity | number }} Total</b>
        <b v-if="ticketBlock.tickets.remaining > 0">
          {{ ticketBlock.tickets.remaining | number }} Disponíve{{
            ticketBlock.tickets.remaining != 1 ? "is" : "l"
          }}
        </b>
        <b v-else>Esgotado</b>
      </div>
    </v-progress-linear>
    <div class="px-4 pb-3 pt-2">
      <div class="mb-4">
        <div v-if="party.active" class="float-right">
          <v-btn v-if="config.opMode" icon small @click.stop="copy">
            <v-icon x-small>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn
            text
            small
            @click.stop="editBlock(ticketBlock)"
            :disabled="!!loading"
          >
            <v-icon x-small left>mdi-pencil</v-icon>
            Lote
          </v-btn>
        </div>
        <div>
          <base-copier
            v-if="config.opMode"
            type="chip"
            :value="ticketBlock.id || ''"
            hide-icon
          >
            <template v-slot:default="{ copy, icon }">
              <v-chip
                @click="copy"
                x-small
                label
                class="text-overline font-weight-medium pl-1 text-0"
              >
                #{{ ticketBlock.id | shortId }}
                <v-icon right x-small>{{ icon }}</v-icon>
              </v-chip>
            </template>
          </base-copier>
          <h6 class="mb-0">
            {{ ticketBlock.name }}
          </h6>
          <v-tooltip top color="black" :disabled="!membershipPrices.length">
            <template v-slot:activator="{ on }">
              <h5 v-on="on" class="mb-0 font-weight-medium">
                {{ ticketBlock.price | currency(true) }}
                <template v-if="membershipPrices.length">
                  | <v-icon small>mdi-card-account-details-star</v-icon>
                </template>
              </h5>
            </template>
            <div class="d-flex flex-column gap-1">
              <div
                v-for="msPrice in membershipPrices"
                :key="msPrice.membershipId"
                class="d-flex gap-2 align-center my-2"
              >
                <v-chip
                  :color="msPrice.membership.backgroundColor"
                  text-color="white"
                  label
                  small
                  class="text-13 font-weight-bold"
                  :style="{
                    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${msPrice.membership.backgroundImg})`,
                    backgroundSize: 'cover',
                    border: `1px solid ${
                      msPrice.membership.backgroundColor || '#000'
                    }`,
                    boxShadow: `0 0 10px  ${
                      msPrice.membership.backgroundColor || 'rgba(0,0,0,.2)'
                    }`,
                  }"
                >
                  {{ msPrice.membership.name }}
                </v-chip>
                <b>{{ msPrice.price | currency(true) }}</b>
              </div>
            </div>
          </v-tooltip>
        </div>
      </div>
      <div
        class="absolute bottom d-flex align-end justify-space-between"
        :class="{ 'non-interactive': selectionMode }"
      >
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <p class="mb-0 lh-1" v-bind="attrs" v-on="on">
                {{ sellers.total }} vendedor{{ sellers.total != 1 ? "es" : "" }}
              </p>
            </template>
            <p class="mb-0">
              {{ sellers.users }} Promoter{{ sellers.users != 1 ? "s" : "" }}
            </p>
            <p class="mb-0">
              {{ sellers.pos }} PDV{{ sellers.pos != 1 ? "s" : "" }}
            </p>
          </v-tooltip>

          <v-btn
            @click="toogleProp('active')"
            x-small
            label
            :loading="loading === 'active'"
            :disabled="!!loading || success === 'active'"
            :color="status.color"
            class="font-weight-bold text-capitalize"
            depressed
          >
            {{ status.text }}
          </v-btn>
        </div>

        <div class="d-flex align-end gap-1">
          <v-tooltip v-if="ticketBlock.KitItem.length" top>
            <template v-slot:activator="{ on }">
              <span v-on="on" icon small>
                <v-icon v-on="on"> mdi-alpha-k-box </v-icon>
              </span>
            </template>
            <span> Vendido dentro de Kit </span>
          </v-tooltip>
          <v-tooltip v-if="ticketBlock.BankRestriction" top>
            <template v-slot:activator="{ on }">
              <div v-on="on" style="width: 28px; height: 28px">
                <v-img
                  :src="ticketBlock.BankRestriction.logo"
                  width="28"
                  height="28"
                  contain
                  class="rounded"
                />
              </div>
            </template>
            <span>
              Vendas restritas ao banco
              <b>{{ ticketBlock.BankRestriction.name }}</b>
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :loading="loading === 'allowTransfer'"
                :disabled="!!loading"
                :color="success === 'allowTransfer' ? 'success' : ''"
                @click="toogleProp('allowTransfer')"
                small
              >
                <v-icon>
                  mdi-{{
                    ticketBlock.allowTransfer
                      ? "account-switch"
                      : "account-lock"
                  }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              Transferência
              <b>
                {{ ticketBlock.allowTransfer ? "permitida" : "não permitida" }}
              </b>
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :loading="loading === 'onlineSale'"
                :disabled="!!loading"
                :color="success === 'onlineSale' ? 'success' : ''"
                @click="toogleProp('onlineSale')"
                small
              >
                <v-icon v-on="on">
                  mdi-earth{{ ticketBlock.onlineSale ? "" : "-off" }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              Venda online
              <b>{{
                ticketBlock.onlineSale ? "permitida" : "não permitida"
              }}</b>
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
export default {
  data: () => ({
    loading: false,
    success: false,
    timeout: null,
  }),
  methods: {
    async toogleProp(prop) {
      try {
        this.loading = prop;
        this.success = false;
        clearTimeout(this.timeout);

        const orgId = this.party.organizationId;
        const partyId = this.party.id;

        const formattedData = this.getFormattedData();
        const ticketBlockId = this.ticketBlock.id;

        const data = {
          [prop]: !this.ticketBlock[prop],
        };

        await TICKET.updateTicketBlock(orgId, partyId, ticketBlockId, data);

        this.ticketBlock[prop] = data[prop];
        this.success = prop;
        this.timeout = setTimeout(() => {
          this.success = false;
        }, 3000);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    getFormattedData() {
      return {
        ...this.ticketBlock,
        sellers: this.ticketBlock.Sellers.map((s) => s.id),
        Sellers: undefined,
        posSellers: this.ticketBlock.PosSellers.map((s) => s.id),
        PosSellers: undefined,
      };
    },

    onClick() {
      if (this.selectionMode) {
        this.$emit("toggleSelection", this.ticketBlock);
      } else {
      }
    },

    editBlock(obj) {
      this.$root.$emit("ticket-block-modal", obj);
    },
    copy() {
      var name = this.ticketBlock.name;

      if (/\d/.test(name))
        name = name.replace(/\d+/g, function (n) {
          return ++n;
        });
      else name = `${name} (Cópia)`;

      const json = JSON.stringify({
        ...this.ticketBlock,
        _copyType: "ticket-block",
        id: undefined,
        name: name,
        tickets: undefined,
      });

      window.sessionStorage.setItem("copy-op", json);

      navigator.clipboard.writeText(json);
      alert("Lote copiado para a área de transferência");
    },
  },
  computed: {
    ...mapGetters("organization", ["memberships"]),
    ...mapGetters("auth", ["config"]),

    membershipPrices() {
      const { memberships } = this;
      if (!memberships) return [];
      const { TicketBlockMembership } = this.ticketBlock;
      if (!TicketBlockMembership) return [];
      return TicketBlockMembership.map((t) => {
        const membership = memberships.find((m) => m.id == t.membershipId);
        return {
          ...t,
          membership,
        };
      });
    },
    sellers() {
      return {
        total:
          this.ticketBlock.Sellers.length + this.ticketBlock.PosSellers.length,
        users: this.ticketBlock.Sellers.length,
        pos: this.ticketBlock.PosSellers.length,
      };
    },
    progressColor() {
      const ticketBlock = this.ticketBlock;
      const percentage =
        (ticketBlock.quantity - ticketBlock.tickets.remaining) /
        ticketBlock.quantity;

      return percentage > 0.9
        ? "error"
        : percentage > 0.7
        ? "warning"
        : "primary";
    },
    status() {
      const ticketBlock = this.ticketBlock;
      if (!ticketBlock.active)
        return {
          text: "Inativo",
          color: "error",
        };
      if (moment().isAfter(ticketBlock.endDate))
        return {
          text: "Encerrado",
          color: "error",
        };
      if (!ticketBlock.startDate || moment().isBefore(ticketBlock.startDate))
        return {
          text: "Aguardando",
          color: "warning",
        };
      if (ticketBlock.tickets.remaining == 0)
        return {
          text: "Esgotado",
          color: "error",
        };
      return {
        text: "Ativo",
        color: "success",
      };
    },
  },
  props: {
    ticketBlock: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    selectionMode: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.ticket-block-management.selected {
  border: 3px solid blue;
}

.non-interactive {
  pointer-events: none;
}
</style>
