<template>
  <div class="my-4 d-flex flex-column gap-4">
    <v-card outlined class="pa-3">
      <h6 class="mb-2">Facebook Pixel</h6>
      <v-text-field
        v-model="fbPixelId"
        label="Facebook Pixel ID"
        outlined
        dense
        placeholder="Cole o script do Pixel"
        :rules="rules.fbPixelId"
        hide-details="auto"
      ></v-text-field>
    </v-card>
    <v-card outlined class="pa-3">
      <h6 class="mb-2">Webhook</h6>
      <v-text-field
        v-model="webhook"
        label="Webhook URL"
        outlined
        dense
        placeholder="https://webhook.site"
        :rules="rules.webhook"
        hide-details="auto"
      ></v-text-field>
    </v-card>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fbPixelId: this.party?.fbPixelId,
      webhook: this.party?.webhook,
      rules: {
        fbPixelId: [
          (v) => !v || new RegExp(/(\d{15,16})/g).test(v) || "ID inválido",
        ],
        // regex for webhook needs to verify if the URL is valid ands starts with https in 2 rules to better error message
        webhook: [
          (v) =>
            !v ||
            new RegExp(
              /^(http(s?):\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
            ).test(v) ||
            "URL inválida",
          (v) =>
            !v ||
            new RegExp(/^(https:\/\/)/).test(v) ||
            "URL deve começar com https://",
        ],
      },
    };
  },
  methods: {
    hasChanges() {
      if (this.fbPixelId !== this.party.fbPixelId) return true;
      if (this.webhook !== this.party.webhook) return true;
      return false;
    },
    save() {
      if (!this.hasChanges()) return false;
      const pixel = this.fbPixelId ? this.fbPixelId.trim() : null;

      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        webhook: this.webhook || null,
        fbPixelId: pixel,
      });
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },

  watch: {
    party: {
      handler: function (val) {
        this.fbPixelId = val.fbPixelId;
      },
      deep: true,
    },
    fbPixelId: {
      handler: function (val) {
        if (!val) return;
        const regex = /fbq\('init', '(\d+)'\);/g;
        const id = regex.exec(val);
        if (!id) return;

        if (id) this.fbPixelId = id[1];
        else this.fbPixelId = "";
      },
      immediate: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
