<template>
  <div class="ma-2 py-2 d-flex flex-column gap-4">
    <v-card outlined class="pa-3">
      <h6>Alvará</h6>
      <v-file-input
        v-if="!party.permitDocument"
        v-model="permitPdf"
        label="Alvará de funcionamento"
        accept=".pdf"
        outlined
        hide-details
        dense
      />

      <v-alert class="mb-0" v-else color="info" dense text>
        <div class="d-flex w-full flex-wrap align-center justify-space-between">
          <span class="flex-grow-1">
            Alvará de funcionamento já cadastrado.
          </span>
          <v-btn :href="party.permitDocument" target="_blank" text small>
            <v-icon small left>mdi-file</v-icon>
            Ver alvará
          </v-btn>
          <v-btn
            icon
            depressed
            small
            color="error"
            @click="party.permitDocument = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </v-card>
    <v-card outlined class="pa-3">
      <h6>Condições gerais do evento</h6>
      <v-file-input
        v-if="!party.generalConditionsDocument"
        v-model="generalConditionsPdf"
        label="Condições gerais do evento"
        accept=".pdf"
        outlined
        hide-details
        dense
      />

      <v-alert class="mb-0" v-else color="info" dense text>
        <div class="d-flex w-full flex-wrap align-center justify-space-between">
          <span class="flex-grow-1">
            Condições gerais do evento já cadastradas.
          </span>
          <v-btn
            :href="party.generalConditionsDocument"
            target="_blank"
            text
            small
          >
            <v-icon small left>mdi-file</v-icon>
            Ver condições
          </v-btn>
          <v-btn
            icon
            depressed
            small
            color="error"
            @click="party.generalConditionsDocument = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </v-card>
    <v-card outlined class="pa-3">
      <h6>Template de autorização para menores</h6>
      <v-file-input
        v-if="!party.authorizationTemplate"
        v-model="authorizationPdf"
        label="Template de autorização para menores"
        accept=".pdf"
        outlined
        hide-details
        dense
      />

      <v-alert class="mb-0" v-else color="info" dense text>
        <div class="d-flex w-full flex-wrap align-center justify-space-between">
          <span class="flex-grow-1">
            Template de autorização para menores já cadastrado.
          </span>
          <v-btn :href="party.authorizationTemplate" target="_blank" text small>
            <v-icon small left>mdi-file</v-icon>
            Ver template
          </v-btn>
          <v-btn
            icon
            depressed
            small
            color="error"
            @click="party.authorizationTemplate = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </v-card>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
export default {
  data() {
    return {
      permitPdf: null,
      authorizationPdf: null,
      generalConditionsPdf: null,
    };
  },
  methods: {
    async save() {
      const hasAuthorization =
        this.party.authorizationDocument || this.authorizationPdf;
      const hasPermit = this.party.permitDocument || this.permitPdf;
      const hasGeneralConditions =
        this.party.generalConditionsDocument || this.generalConditionsPdf;

      const partyUpdate = await PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        permitDocument: hasPermit ? undefined : null,
        authorizationDocument: hasAuthorization ? undefined : null,
        generalConditionsDocument: hasGeneralConditions ? undefined : null,
      });
      if (this.permitPdf)
        await this.saveDocument(this.permitPdf, "permit-document");
      if (this.authorizationPdf)
        await this.saveDocument(
          this.authorizationPdf,
          "authorization-template"
        );
      if (this.generalConditionsPdf)
        await this.saveDocument(
          this.generalConditionsPdf,
          "general-conditions"
        );

      return partyUpdate;
    },
    saveDocument(document, type) {
      return PARTY.document(
        this.party.organizationId,
        this.party.id,
        type,
        document
      );
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
