<template>
  <div class="card-container" :class="{ dark: $vuetify.theme.dark }">
    <v-card outlined elevation="3" rounded="lg" style="z-index: 3">
      <v-progress-linear
        :color="`blue-grey ${$vuetify.theme.dark ? 'darken-4' : 'lighten-4'}`"
        :background-color="`${progressColor} lighten-1`"
        :value="((kit.quantity - kit.kits.remaining) / kit.quantity) * 100"
        height="20"
      >
        <div class="d-flex caption ml-2 justify-space-around w-full">
          <b>{{ kit.quantity }} Total</b>
          <b v-if="kit.kits.remaining > 0">
            {{ kit.kits.remaining }} Disponíve{{
              kit.kits.remaining != 1 ? "is" : "l"
            }}
          </b>
          <b v-else> Esgotado</b>
        </div>
      </v-progress-linear>
      <div class="px-4 pb-3 pt-2">
        <div class="mb-4">
          <div v-if="party.active" class="float-right">
            <v-btn text small @click="editKit(kit)">
              <v-icon x-small left>mdi-pencil</v-icon>
              Kit
            </v-btn>
            <div v-if="config.opMode" class="d-flex justify-end">
              <v-btn icon small @click="copy">
                <v-icon x-small>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <base-copier
              v-if="config.opMode"
              type="chip"
              :value="kit.id || ''"
              hide-icon
            >
              <template v-slot:default="{ copy, icon }">
                <v-chip
                  @click="copy"
                  x-small
                  label
                  class="text-overline font-weight-medium pl-1 text-0"
                >
                  #{{ kit.id | shortId }}
                  <v-icon right x-small>{{ icon }}</v-icon>
                </v-chip>
              </template>
            </base-copier>
            <h6 class="mb-0">
              {{ kit.name }}
            </h6>
            <h5 class="mb-0 font-weight-medium">
              {{ kit.price | currency(true) }}
            </h5>
          </div>
        </div>
        <div class="absolute bottom d-flex align-end justify-space-between">
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <p class="mb-0 lh-1" v-bind="attrs" v-on="on">
                  {{ sellers.total }} vendedor{{
                    sellers.total != 1 ? "es" : ""
                  }}
                </p>
              </template>
              <p class="mb-0">
                {{ sellers.users }} Promoter{{ sellers.users != 1 ? "s" : "" }}
              </p>
              <p class="mb-0">
                {{ sellers.pos }} PDV{{ sellers.pos != 1 ? "s" : "" }}
              </p>
            </v-tooltip>
            <div class="d-flex gap-1">
              <v-btn
                @click="toogleProp('active')"
                x-small
                label
                :loading="loading === 'active'"
                :disabled="!!loading || success === 'active'"
                :color="status.color"
                class="font-weight-bold text-capitalize"
                depressed
              >
                {{ status.text }}
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-on="on"
                    x-small
                    label
                    color="info"
                    class="font-weight-bold"
                    style="height: 20px"
                  >
                    {{ itemsCount }} ite{{ itemsCount != 1 ? "ns" : "m" }}
                  </v-chip>
                </template>
                <p v-for="i in kit.Items" :key="i.id" class="mb-0">
                  {{ i.quantity }}x {{ i.TicketBlock.TicketGroup.name }} -
                  {{ i.TicketBlock.name }}
                </p>
              </v-tooltip>
            </div>
          </div>

          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  :loading="loading === 'onlineSale'"
                  :disabled="!!loading"
                  :color="success === 'onlineSale' ? 'success' : ''"
                  @click="toogleProp('onlineSale')"
                  small
                >
                  <v-icon v-on="on">
                    mdi-earth{{ kit.onlineSale ? "" : "-off" }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Venda online
                <b> {{ kit.onlineSale ? "permitida" : "não permitida" }} </b>
              </span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-card>
    <div class="stack-layer" />
    <div class="stack-layer" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import TICKET from "@/services/admin/ticket";

export default {
  data() {
    return {
      loading: false,
      success: false,
      timeout: null,
    };
  },
  methods: {
    async toogleProp(prop) {
      try {
        this.loading = prop;
        this.success = false;
        clearTimeout(this.timeout);

        const orgId = this.party.organizationId;
        const partyId = this.party.id;

        const data = this.getFormattedData();
        data[prop] = !this.kit[prop];

        await TICKET.updateKit(orgId, partyId, data);

        this.kit[prop] = data[prop];
        this.success = prop;
        this.timeout = setTimeout(() => {
          this.success = false;
        }, 3000);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getFormattedData() {
      const items = { ticketBlockList: [] };
      this.kit.Items.forEach((i) => {
        items.ticketBlockList.push({
          ticketBlockId: i.TicketBlock.id,
          TicketBlock: i.TicketBlock,
          quantity: i.quantity,
        });
      });

      return {
        ...this.kit,
        sellers: this.kit.Sellers.map((s) => s.id),
        Sellers: undefined,
        posSellers: this.kit.PosSellers.map((s) => s.id),
        PosSellers: undefined,
        Items: undefined,
        items,
      };
    },
    editKit(data) {
      this.$root.$emit("kit-modal", data);
    },
    copy() {
      var name = this.kit.name;

      if (/\d/.test(name))
        name = name.replace(/\d+/g, function (n) {
          return ++n;
        });
      else name = `${name} (Cópia)`;

      const json = JSON.stringify({
        ...this.kit,
        _copyType: "kit",
        id: undefined,
        name: name,
      });

      window.sessionStorage.setItem("copy-op", json);

      navigator.clipboard.writeText(json);
      alert("Kit copiado para a área de transferência");
    },
  },
  computed: {
    ...mapGetters("auth", ["config"]),
    progressColor() {
      const kit = this.kit;
      const percentage = (kit.quantity - kit.kits.remaining) / kit.quantity;

      return percentage > 0.9
        ? "error"
        : percentage > 0.7
        ? "warning"
        : "primary";
    },
    itemsCount() {
      return this.kit.Items.reduce((acc, item) => acc + item.quantity, 0);
    },
    sellers() {
      return {
        total: this.kit.Sellers.length + this.kit.PosSellers.length,
        users: this.kit.Sellers.length,
        pos: this.kit.PosSellers.length,
      };
    },
    status() {
      const kit = this.kit;
      if (!kit.active)
        return {
          text: "Inativo",
          color: "error",
        };
      if (moment().isAfter(kit.endDate))
        return {
          text: "Encerrado",
          color: "error",
        };
      if (!kit.startDate || moment().isBefore(kit.startDate))
        return {
          text: "Aguardando",
          color: "warning",
        };
      if (kit.kits.remaining <= 0)
        return {
          text: "Esgotado",
          color: "error",
        };
      return {
        text: "Ativo",
        color: "success",
      };
    },
  },
  props: {
    kit: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
// Definir variáveis para as cores das sombras
$shadow-light: rgba(0, 0, 0, 0.1);
$shadow-dark: rgba(255, 255, 255, 0.02);

$background-light: #f9f9f9;
$background-dark: #333333;

// Mixin para aplicar sombra
@mixin shadow($color) {
  box-shadow: 0 2px 4px $color;
}

// Mixin para aplicar sombra de empilhamento
@mixin stack-shadow($color) {
  box-shadow: 0 4px 6px $color, 0 6px 8px $color;
}

// Estilo do Card
.card-container {
  position: relative;
  width: 100%;

  &.dark {
    .kit-card {
      background-color: $background-dark;
      @include shadow($shadow-dark);
    }

    .stack-layer {
      background-color: $background-dark;
      border: $background-dark 1px solid;
      @include stack-shadow($shadow-dark);

      &:nth-child(3) {
        background-color: darken($background-dark, 10%);
      }
    }
  }
}

// .kit-card {
//   border: 1px solid #ddd;
//   padding: 10px;
//   border-radius: 5px;
//   background-color: $background-light;
//   position: relative;
//   z-index: 3;
//   @include shadow($shadow-light);
// }

// Light
.stack-layer {
  position: absolute;
  top: 6px;
  left: 5px;
  width: 100%;
  height: 100%;
  background-color: $background-light;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: $background-light 1px solid;
  border-radius: 8px;
  z-index: 2;

  &:nth-child(3) {
    top: 11px;
    left: 10px;
    background-color: darken($background-light, 7%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
}
</style>
