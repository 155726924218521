<template>
  <v-alert v-if="error" type="error" :value="error" />
  <v-select
    v-else
    v-model="selectedBank"
    :items="banks || []"
    :loading="loading"
    :label="label"
    item-text="name"
    item-value="id"
    @focus="getBanks"
    hide-details="auto"
    :clearable="clearable"
    outlined
  >
    <template v-slot:item="{ item }">
      <div class="d-flex align-center gap-2">
        <div>
          <v-img
            :src="item.logo"
            width="40"
            height="40"
            contain
            class="rounded"
          />
        </div>
        {{ item.name }}
        <small>({{ item.code }})</small>
      </div>
    </template>
  </v-select>
</template>

<script>
import BANK from "@/services/api/bank";
export default {
  name: "BankSelect",
  props: {
    label: {
      type: String,
      default: "Selecione o banco",
    },
    value: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,

      error: false,
      banks: null,
    };
  },
  computed: {
    selectedBank: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async getBanks() {
      try {
        if (this.banks) return;
        this.loading = true;
        const response = await BANK.getAll();
        this.banks = response.banks;
      } catch (error) {
        this.error = error.message || "Erro ao buscar bancos";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.value) this.getBanks();
  },
};
</script>
