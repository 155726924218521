<template>
  <div>
    <div class="d-flex flex-column gap-2">
      <!-- PrePurchase Alert -->
      <v-card outlined class="pa-3">
        <div class="d-flex align-center justify-space-between">
          <v-switch
            class="mt-0"
            :input-value="!!ticketBlock.prePurchaseAlert"
            @change="changePrePurchaseAlert"
            label="Alerta de pré-compra"
            :messages="[
              ticketBlock.prePurchaseAlert
                ? 'Alerta de pré-compra ativado'
                : 'Alerta de pré-compra desativado',
            ]"
          />
          <v-btn
            v-if="!!ticketBlock.prePurchaseAlert"
            @click="openPrePurchaseAlertModal"
            text
            small
            :disabled="
              !ticketBlock.prePurchaseAlert.title ||
              !ticketBlock.prePurchaseAlert.message
            "
          >
            <v-icon small left>mdi-eye</v-icon>
            Previsualizar
          </v-btn>
        </div>

        <div v-if="!!ticketBlock.prePurchaseAlert" class="mt-4">
          <v-text-field
            v-model="ticketBlock.prePurchaseAlert.title"
            label="Título"
            outlined
            dense
            :rules="[
              (v) => !!v || 'Título é obrigatório',
              (v) =>
                v.length <= 50 || 'Título deve ter no máximo 50 caracteres',
            ]"
          />
          <v-textarea
            v-model="ticketBlock.prePurchaseAlert.message"
            label="Mensagem"
            outlined
            dense
            hide-details="auto"
            :rules="[
              (v) => !!v || 'Mensagem é obrigatória',
              (v) =>
                v.length <= 200 || 'Mensagem deve ter no máximo 200 caracteres',
            ]"
          />
        </div>
      </v-card>

      <!-- Bank Restriction -->
      <v-card outlined class="pa-3">
        <v-switch
          class="mt-0"
          :input-value="!!ticketBlock.bankRestrictionId"
          @change="changeBankRestriction"
          label="Restringir vendas ao banco"
          :messages="[
            ticketBlock.prePurchaseAlert
              ? 'Alerta de pré-compra ativado'
              : 'Alerta de pré-compra desativado',
          ]"
        />
        <bank-select
          v-if="ticketBlock.bankRestrictionId !== null"
          v-model="ticketBlock.bankRestrictionId"
          label="Restringir vendas ao banco:"
        />
      </v-card>

      <!-- Ticket Use Requirement -->
      <v-card outlined class="pa-3">
        <v-switch
          :input-value="
            !!ticketBlock.useRequirement || ticketBlock.useRequirement === ''
          "
          @change="changeTicketUseRequirement"
          label="Requisito para uso do ingresso"
          messages="Mensagem exibida ao usuário na compra e no ingresso"
          class="mt-0"
        />

        <template
          v-if="
            !!ticketBlock.useRequirement || ticketBlock.useRequirement === ''
          "
        >
          <v-chip-group
            v-model="ticketBlock.useRequirement"
            column
            active-class="primary--text"
            class="my-2"
          >
            <v-chip
              v-for="suggestion in ticketRequirementSugestions"
              :key="suggestion.name"
              :value="suggestion.text"
              small
            >
              {{ suggestion.name }}
            </v-chip>
          </v-chip-group>
          <v-text-field
            v-model="ticketBlock.useRequirement"
            label="Requisito para uso"
            hide-details="auto"
            outlined
            dense
            counter="40"
            maxlength="40"
          />
        </template>
      </v-card>
    </div>
    <pre-purchase-alert-modal ref="prePurchaseAlertModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PrePurchaseAlertModal from "../../../shop/PrePurchaseAlertModal.vue";
import BankSelect from "@/components/global/BankSelect.vue";

const defaultTicketBlock = () => ({
  id: null,
  bankRestrictionId: null,
  prePurchaseAlert: false,
  useRequirement: null,
});
export default {
  components: {
    BankSelect,
    PrePurchaseAlertModal,
  },
  emits: ["input"],
  data: () => ({
    valid: false,
    disableRules: false,
    ticketRequirementSugestions: [
      {
        name: "Solidario",
        text: "+ 1kg de alimento não perecivel",
      },
      {
        name: "Comprovante meia-entrada",
        text: "Comprovante de direito a meia-entrada",
      },
    ],
  }),
  methods: {
    changeBankRestriction(event) {
      this.$set(this.ticketBlock, "bankRestrictionId", event ? "" : null);
    },
    changePrePurchaseAlert(event) {
      const prePurchaseAlert = event ? { title: "", message: "" } : null;
      this.$set(this.ticketBlock, "prePurchaseAlert", prePurchaseAlert);
    },
    changeTicketUseRequirement(event) {
      const useRequirement = event ? "" : null;
      this.$set(this.ticketBlock, "useRequirement", useRequirement);
    },
    openPrePurchaseAlertModal() {
      this.$refs.prePurchaseAlertModal.open(
        Object.assign({}, this.ticketBlock)
      );
    },
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["config"]),
    ticketBlock: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      },
    },
  },
  props: {
    value: {
      type: Object,
      default: defaultTicketBlock,
    },
  },
};
</script>

<style></style>
