<template>
  <div class="ma-2 py-2 d-flex flex-column gap-4">
    <v-row>
      <v-col cols="12" md="5">
        <v-card outlined class="pa-2 rounded-lg">
          <p class="mb-2">Preview</p>
          <div class="preview-container">
            <party-cover-video :party="previewParty" /></div></v-card
      ></v-col>
      <v-col cols="12" md="7">
        <v-select
          v-model="selectedHoliday"
          :items="holidays"
          item-text="text"
          item-value="value"
          label="Data Comemorativa"
          hide-details="auto"
          outlined
          dense
          clearable
          :loading="loading"
        >
          <template v-slot:selection="{ item }">
            <v-icon small class="mr-2">{{ item.icon }}</v-icon>
            {{ item.text }}
          </template>
          <template v-slot:item="{ item }">
            <v-icon small class="mr-2">{{ item.icon }}</v-icon>
            {{ item.text }}
          </template>
        </v-select>
        <div class="mt-2 text-caption" v-if="selectedHoliday">
          <v-alert type="info" text dense>
            O efeito será aplicado à página do evento.
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import PartyCoverVideo from "@/components/shop/PartyCoverVideo.vue";

export default {
  components: {
    PartyCoverVideo,
  },
  data: () => ({
    loading: false,
    selectedHoliday: null,
    showPreview: false,
    holidays: [
      { text: "Nenhum", value: null, icon: "mdi-cancel" },
      { text: "Carnaval", value: "CARNIVAL", icon: "mdi-party-popper" },
      { text: "Festa Junina", value: "FESTA_JUNINA", icon: "mdi-campfire" },
      { text: "Dia dos Namorados", value: "VALENTINES_DAY", icon: "mdi-heart" },
      { text: "Halloween", value: "HALLOWEEN", icon: "mdi-ghost" },
      { text: "Natal", value: "CHRISTMAS", icon: "mdi-pine-tree" },
      { text: "Ano Novo", value: "NEW_YEAR", icon: "mdi-calendar-plus" },
    ],
  }),
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  watch: {
    party: {
      immediate: true,
      handler(party) {
        if (party) {
          this.selectedHoliday = party.holidayType || null;
        }
      },
    },
    showPreview(newVal) {
      if (newVal) {
        this.effectInterval = this.startHolidayEffect();
      } else {
        clearInterval(this.effectInterval);
      }
    },
  },
  methods: {
    hasChanges() {
      if (this.selectedHoliday !== this.party.holidayType) return true;
      return false;
    },
    async save() {
      if (!this.hasChanges()) return false;
      try {
        this.loading = true;
        await PARTY.update({
          id: this.party.id,
          organizationId: this.party.organizationId,
          holidayType: this.selectedHoliday,
        });
        return true;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    startHolidayEffect() {
      if (!this.selectedHoliday) return;

      // Executa o efeito imediatamente e depois a cada 3 segundos
      this.triggerEffect();
      const effectInterval = setInterval(() => {
        this.triggerEffect();
      }, 3000);

      return effectInterval;
    },
    triggerEffect() {
      if (!window.confetti) {
        console.error("tsParticles confetti não está carregado");
        return;
      }

      switch (this.selectedHoliday) {
        case "VALENTINES_DAY":
          this.valentineEffect();
          break;
        case "NEW_YEAR":
          this.newYearEffect();
          break;
        case "CHRISTMAS":
          this.christmasEffect();
          break;
        case "HALLOWEEN":
          this.halloweenEffect();
          break;
      }
    },
    valentineEffect() {
      const defaults = {
        spread: 360,
        ticks: 100,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        shapes: ["heart"],
        colors: ["FFC0CB", "FF69B4", "FF1493", "C71585"],
      };

      window.confetti({
        ...defaults,
        particleCount: 50,
        scalar: 2,
      });

      window.confetti({
        ...defaults,
        particleCount: 25,
        scalar: 3,
      });

      window.confetti({
        ...defaults,
        particleCount: 10,
        scalar: 4,
      });
    },
    newYearEffect() {
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
      const randomInRange = (min, max) => Math.random() * (max - min) + min;

      window.confetti({
        ...defaults,
        particleCount: 50,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });

      window.confetti({
        ...defaults,
        particleCount: 50,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    },
    christmasEffect() {
      const randomInRange = (min, max) => Math.random() * (max - min) + min;

      window.confetti({
        particleCount: 1000,
        startVelocity: 0,
        ticks: 200,
        colors: ["#ffffff"],
        shapes: ["circle"],
        gravity: randomInRange(0.4, 0.6),
        scalar: randomInRange(0.4, 1),
        drift: randomInRange(-0.4, 0.4),
      });
    },
    halloweenEffect() {
      const defaults = {
        scalar: 2,
        spread: 270,
        particleCount: 25,
        origin: { y: 0.4 },
        startVelocity: 35,
      };

      window.confetti({
        ...defaults,
        shapes: ["image"],
        shapeOptions: {
          image: {
            src: "https://particles.js.org/images/pumpkin.svg",
            replaceColor: true,
            width: 32,
            height: 40,
          },
        },
        colors: ["#ff9a00", "#ff7400", "#ff4d00"],
      });
    },
  },
  mounted() {
    if (this.showPreview) {
      this.effectInterval = this.startHolidayEffect();
    }
  },
  beforeDestroy() {
    if (this.effectInterval) {
      clearInterval(this.effectInterval);
    }
  },
  computed: {
    previewParty() {
      return {
        ...this.party,
        holidayType: this.selectedHoliday,
      };
    },
  },
};
</script>

<style scoped>
.preview-container {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}
</style>
