import http from "../../http-common";

const getAll = async () => {
  return await http
    .get(`/api/bank`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getAll,
};

