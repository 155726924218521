<template>
  <div>
    <base-card :loading="loading" class="rounded-lg pb-2">
      <div
        class="d-flex justify-center text--disabled align-center gap-1 pt-1 unselectable"
      >
        <small class="lh-1">
          {{ party?.name || "Carregando..." }}
        </small>
        <small class="lh-1 pl-1"> • </small>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              text
              class="text--disabled px-1 text-capitalize text-caption"
            >
              Operacional
            </v-btn>
          </template>
          <v-card class="d-flex flex-column pa-1 rounded-lg">
            <v-btn
              small
              text
              class="justify-start"
              v-if="hasPermission(2)"
              :to="{
                name: 'admin.party.details',
                params: { partyId: party?.id },
              }"
            >
              <v-icon left>mdi-eye</v-icon>
              Detalhes
            </v-btn>
            <v-btn
              small
              text
              class="justify-start"
              v-if="hasPermission(131072)"
              :to="{
                name: 'admin.entrance.management',
                params: { partyId: party?.id },
              }"
            >
              <v-icon left>mdi-qrcode</v-icon>
              Entrada
            </v-btn>
          </v-card>
        </v-menu>
      </div>
      <v-tabs v-model="tab" show-arrows class="rounded-lg" height="36px">
        <v-tab
          v-for="tab in loading ? [] : tabsFiltered"
          class="rounded-lg"
          :key="tab.component"
        >
          <v-icon left small>{{ tab.icon }}</v-icon>
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" :touchless="tabsFiltered[tab]?.touchless">
        <v-tab-item
          v-for="item in loading ? [] : tabsFiltered"
          :key="item.name"
        >
          <component
            :is="item.component"
            :party="party"
            @refresh="getParty"
            class="px-4 pb-4"
          />
        </v-tab-item>
      </v-tabs-items>

      <refund-ticket :party="party" />
    </base-card>

    <tecnician-daily :party="party" />

    <!-- Navgation  -->
    <v-bottom-sheet
      v-model="navigationSheet"
      inset
      max-width="550px"
      :overlay-opacity="0.8"
      content-class="elevation-0"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          style="position: fixed; bottom: 76px; right: 16px; z-index: 5"
          float
          v-bind="attrs"
          v-on="on"
          color="primary"
        >
          <v-icon> mdi-dots-grid </v-icon>
        </v-btn>
      </template>
      <div class="mb-4 px-4 d-flex">
        <v-card class="pa-3 pb-1 rounded-lg">
          <v-row dense class="mx-0">
            <v-col
              v-for="tab in tabsFiltered"
              :key="tab?.id"
              cols="4"
              sm="4"
              md="3"
            >
              <v-card
                @click="selectTab(tab?.id)"
                outlined
                height="90px"
                elevation="0"
                class="pa-2"
              >
                <div
                  class="d-flex align-center justify-center flex-column h-full text-center lh-1 gap-2"
                >
                  <v-icon>{{ tab.icon }}</v-icon>
                  {{ tab.name }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="d-flex justify-center">
            <v-btn @click="navigationSheet = false" text small> Fechar </v-btn>
          </div>
        </v-card>
      </div>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PartyTicketsManagement from "@/components/admin/party/PartyTicketsManagement";
import PartyTickets from "@/components/admin/party/PartyTicketsSold.vue";
import PartyReports from "@/components/admin/party/PartyReports.vue";
import PartyLiveAnalytics from "@/components/admin/party/PartyLiveAnalytics.vue";
import OfflinePaymentsManagement from "@/components/admin/party/OfflinePaymentsManagement.vue";
import SaleLinkManagement from "@/components/admin/party/SaleLinkManagement.vue";
import TicketRefunds from "@/components/admin/party/TicketRefunds.vue";
import CouponManagement from "@/components/admin/party/CouponManagement.vue";
import CourtesyEmitter from "@/components/admin/party/CourtesyEmitter.vue";
import RefundTicket from "@/components/admin/modals/RefundTicket.vue";
import PartySeller from "@/components/admin/party/PartySeller";
import PartyTicketOffice from "@/components/admin/party/PartyTicketOffice";
import TecnicianDaily from "@/components/global/tecnician/TecnicianDaily.vue";
export default {
  metaInfo() {
    return { title: `⚙️ ${this.party?.name || "Carregando..."}` };
  },
  data: () => ({
    loading: true,
    party: null,
    tab: null,
    navigationSheet: false,
    tabs: [
      {
        id: "tickets",
        name: "Ingressos",
        component: "PartyTicketsManagement",
        permission: 16,
        icon: "mdi-ticket",
        touchless: true,
        noFree: true,
      },
      {
        id: "guests",
        name: "Convidados",
        component: "PartyTickets",
        permission: 64,
        icon: "mdi-account-multiple",
        touchless: true,
        noFree: true,
      },
      {
        id: "reports",
        name: "Relatórios",
        component: "PartyReports",
        permission: 512,
        icon: "mdi-chart-bar",
        touchless: true,
        noFree: true,
      },
      {
        id: "analytics",
        name: "Analytics",
        component: "PartyLiveAnalytics",
        permission: 512,
        icon: "mdi-chart-line",
        touchless: true,
      },
      {
        id: "offlinePayments",
        name: "Recebimentos Externos",
        component: "OfflinePaymentsManagement",
        permission: 8192,
        icon: "mdi-tray-arrow-down",
        noFree: true,
      },
      {
        id: "sellers",
        name: "PDV & Vendedores",
        component: "PartySeller",
        permission: [16],
        icon: "mdi-account-group",
        noFree: true,
      },
      {
        id: "ticketOffice",
        name: "Bilheteria",
        component: "PartyTicketOffice",
        permission: [16, 268435456],
        icon: "mdi-ticket-account",
        noFree: true,
      },
      {
        id: "refound",
        name: "Reembolsos",
        component: "TicketRefunds",
        permission: 128,
        icon: "mdi-cash-refund",
        noFree: true,
      },
      {
        id: "saleLinks",
        name: "Links de Venda",
        component: "SaleLinkManagement",
        permission: 2,
        icon: "mdi-qrcode",
        noFree: true,
      },
      {
        id: "coupon",
        name: "Cupons",
        component: "CouponManagement",
        permission: 65536,
        icon: "mdi-ticket-percent",
        noFree: true,
      },
      {
        id: "courtesy",
        name: "Cortesias",
        component: "CourtesyEmitter",
        permission: 524288,
        icon: "mdi-account-star",
        noFree: true,
      },
    ],
  }),
  methods: {
    ...mapActions("organization", ["partyById"]),
    async getParty() {
      try {
        this.loading = true;
        var response = await this.partyById(this.$route.params.partyId);
        this.party = response;
        this.loading = false;
        this.tab = 0;
        this.selectTab();
        this.$root.$emit("setImgBg", this.party?.cover);
      } catch (error) {
        this.$router.push({ name: "admin.parties" });
      }
    },
    selectTab(tab) {
      if (tab) window.location.replace("#" + tab);
      if (this.$route.hash) {
        let hash = tab || this.$route.hash.replace("#", "");
        let index = this.tabsFiltered.findIndex((tab) => tab?.id == hash);
        if (index >= 0) this.tab = index;
        this.navigationSheet = false;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
    tabsFiltered() {
      return this.tabs.filter((tab) => {
        if (this.party.isFree && tab.noFree) return false;
        if (!tab.permission) return true;
        return this.hasPermission(tab.permission);
      });
    },
  },
  watch: {
    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.tabsFiltered[val];
        if (tab) window.location.replace("#" + tab?.id);
      },
      immediate: true,
    },
    "$route.hash"() {
      this.selectTab();
    },
    "$route.params.partyId"() {
      this.getParty();
    },
    "selectedOrganization.id"() {
      this.$router.push({
        name: "admin.parties",
      });
    },
  },
  components: {
    TecnicianDaily,
    PartyTicketsManagement,
    PartyReports,
    PartyLiveAnalytics,
    PartyTickets,
    OfflinePaymentsManagement,
    SaleLinkManagement,
    CourtesyEmitter,
    TicketRefunds,
    RefundTicket,
    PartySeller,
    PartyTicketOffice,
    CouponManagement,
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
  mounted() {
    this.getParty();
  },
};
</script>

<style></style>
