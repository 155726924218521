<template>
  <div>
    <v-dialog
      v-model="isOpen"
      width="700"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
      content-class="rounded-lg"
    >
      <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
        <v-card-title class="pa-0 pt-3 px-0 flex-column">
          <div class="d-flex w-full align-center px-6 gap-2">
            <span class="text-16">
              {{ ticketBlock?.id ? "Editar lote" : "Adicionar lote" }}
            </span>
            <v-spacer />
            <v-tooltip v-if="ticketBlock?.KitItem?.length" top class="lh-1">
              <template v-slot:activator="{ on }">
                <span v-on="on" icon small>
                  <v-icon v-on="on"> mdi-alpha-k-box </v-icon>
                </span>
              </template>
              <span> Vendido dentro de Kit </span>
            </v-tooltip>
            <v-chip label small>
              {{
                this.ticketGroups.find(
                  (g) =>
                    g.id ==
                    (ticketBlock?.ticketGroup || ticketBlock?.ticketGroupId)
                )?.name
              }}
            </v-chip>
          </div>
          <v-tabs
            v-model="step"
            background-color="transparent"
            grow
            show-arrows
            height="40px"
          >
            <v-tab>
              <v-icon small left>mdi-information</v-icon>
              Informações
            </v-tab>
            <v-tab>
              <v-icon small left>mdi-card-account-details-star</v-icon>
              Membros
            </v-tab>
            <v-tab>
              <v-icon small left>mdi-account-group</v-icon>
              Vendedores
            </v-tab>
            <v-tab v-if="config.opMode">
              <v-icon small left>mdi-cog</v-icon>
              Avançado
            </v-tab>
          </v-tabs>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-tabs-items v-model="step" v-if="isOpen">
            <v-tab-item>
              <ticket-block-info
                v-model="ticketBlock"
                :party="party"
                :ticketGroups="ticketGroups"
                :lastTicketBlock="lastTicketBlock"
                @valid="validInfo = $event"
              />
            </v-tab-item>

            <v-tab-item>
              <ticket-block-membership
                v-model="ticketBlock.TicketBlockMembership"
                :ticket-block="ticketBlock"
              />
            </v-tab-item>
            <v-tab-item>
              <ticket-block-sellers
                v-model="sellers"
                :members="members"
                :ticket-block="ticketBlock"
                :posSessions="posSessions"
              />
            </v-tab-item>

            <v-tab-item v-if="config.opMode">
              <ticket-block-advanced
                v-model="ticketBlock"
                :party="party"
                :ticketGroups="ticketGroups"
                :lastTicketBlock="lastTicketBlock"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions class="d-flex align-end gap-1 flex-wrap">
          <v-switch
            class="mt-0"
            v-model="ticketBlock.active"
            label="Ativo"
            :messages="[ticketBlock.active ? 'Lote ativo' : 'Lote inativo']"
          />
          <v-spacer />
          <div class="d-flex gap-2 justify-end flex-grow-1">
            <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
            <v-btn
              v-if="ticketBlock.id && config.opMode"
              color="error"
              :loading="loading"
              @click="deleteTicketBlock"
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              color="success"
              :disabled="!validInfo"
              :loading="loading"
              @click="save"
            >
              Salvar
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ticket-block-delete
      v-if="ticketBlock?.id"
      :ticket-block="ticketBlock"
      @success="$emit('success') && close()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import moment from "moment";
import TicketBlockInfo from "./modules/TicketBlockInfo.vue";
import TicketBlockSellers from "./modules/TicketBlockSellers.vue";
import TicketBlockMembership from "./modules/TicketBlockMembership.vue";
import TicketBlockAdvanced from "./modules/TicketBlockAdvanced.vue";
import TicketBlockDelete from "./TicketBlockDelete.vue";

export default {
  components: {
    TicketBlockInfo,
    TicketBlockSellers,
    TicketBlockMembership,
    TicketBlockAdvanced,
    TicketBlockDelete,
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      validInfo: false,
      validMembership: false,

      error: false,
      step: 1,
      ticketBlock: this.defaultTicketBlock(),
      sellers: [],
    };
  },

  methods: {
    open(data = {}) {
      this.isOpen = true;
      this.step = 0;
      data = { ...this.defaultTicketBlock(), ...data };
      // sellers
      const sellers = [];
      if (data.Sellers) {
        sellers.push(
          ...this.members
            .filter((member) => data.Sellers.some((s) => s.id == member.id))
            .map((s) => ({ ...s, type: "user" }))
        );
      }
      if (data.PosSellers) {
        sellers.push(
          ...this.posSessions
            .filter((session) =>
              data.PosSellers.some((s) => s.id == session.id)
            )
            .map((s) => ({ ...s, type: "pos" }))
        );
      }

      this.sellers = sellers;

      // Format Date
      data.startDate = data.startDate
        ? moment(data.startDate).format("YYYY-MM-DDTHH:mm")
        : data.startDate;
      data.startType = !!data.startDate ? true : false;
      data.endDate = data.endDate
        ? moment(data.endDate).format("YYYY-MM-DDTHH:mm")
        : data.endDate;

      // Assign data
      this.ticketBlock = Object.assign(this.defaultTicketBlock(), data);
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.sellers = [];
      this.ticketBlock = this.defaultTicketBlock();
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        let { partyId } = this.$route.params;

        var startDate = moment(this.ticketBlock.startDate);
        if (!this.ticketBlock.startType) startDate = null;

        const sellersGrouped = this.sellers.reduce(
          (acc, s) => {
            if (!acc[s.type]) acc[s.type] = [];
            acc[s.type].push(s.id);
            return acc;
          },
          { user: [], pos: [] }
        );

        if (this.ticketBlock.id) {
          await TICKET.updateTicketBlock(orgId, partyId, this.ticketBlock.id, {
            ticketGroup: this.ticketBlock.ticketGroup,
            name: this.ticketBlock.name,
            price: this.ticketBlock.price,
            quantity: parseInt(this.ticketBlock.quantity),
            startDate: startDate?.toISOString() || null,
            endDate: moment(this.ticketBlock.endDate).toISOString(),
            allowTransfer: this.ticketBlock.allowTransfer,
            active: this.ticketBlock.active,
            onlineSale: this.ticketBlock.onlineSale,
            sellers: sellersGrouped.user,
            posSellers: sellersGrouped.pos,
            prePurchaseAlert: this.ticketBlock.prePurchaseAlert || null,
            bankRestrictionId: this.ticketBlock.bankRestrictionId || null,
            useRequirement: !!this.ticketBlock.useRequirement
              ? this.ticketBlock.useRequirement
              : null,
            TicketBlockMembership: this.ticketBlock.TicketBlockMembership,
          });
        } else {
          await TICKET.createTicketBlock(orgId, partyId, {
            ticketGroup: this.ticketBlock.ticketGroup,
            name: this.ticketBlock.name,
            price: this.ticketBlock.price,
            quantity: parseInt(this.ticketBlock.quantity),
            startDate: startDate?.toISOString() || null,
            endDate: moment(this.ticketBlock.endDate).toISOString(),
            allowTransfer: this.ticketBlock.allowTransfer,
            active: this.ticketBlock.active,
            onlineSale: this.ticketBlock.onlineSale,
            sellers: sellersGrouped.user,
            posSellers: sellersGrouped.pos,
            prePurchaseAlert: this.ticketBlock.prePurchaseAlert,
            bankRestrictionId: this.ticketBlock.bankRestrictionId || null,
            useRequirement: !!this.ticketBlock.useRequirement
              ? this.ticketBlock.useRequirement
              : null,
            TicketBlockMembership: this.ticketBlock.TicketBlockMembership,
          });
        }

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
    deleteTicketBlock() {
      this.$root.$emit("ticket-block-delete-modal");
    },
    defaultTicketBlock() {
      return {
        id: null,
        name: "",
        price: null,
        quantity: null,
        startDate: moment().format("YYYY-MM-DDTHH:mm"),
        endDate: moment(this.party.endDate).format("YYYY-MM-DDTHH:mm"),
        active: true,
        onlineSale: false,
        allowTransfer: false,
        ticketGroup: null,
        TicketBlockMembership: [],
      };
    },
  },
  watch: {
    "ticketBlock.name": {
      handler: function (val) {
        this.ticketBlock.name = this.formatName(val);
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["config"]),
    ...mapGetters("organization", ["memberships", "selectedOrganization"]),

    steps() {
      const data = ["Informações"];
      if (this.memberships.some((m) => m.active)) data.push("Membros");
      data.push("Vendedores");
      return data;
    },

    lastTicketBlock() {
      if (!this.ticketBlock) return null;
      const group = this.ticketGroups.find(
        (g) =>
          g.id ==
          (this.ticketBlock?.ticketGroup || this.ticketBlock?.ticketGroupId)
      );
      if (!group) return null;

      const ticketBlocks = group.TicketBlock.filter(
        (t) =>
          t.active &&
          this.ticketBlock.id != t.id &&
          t.quantity > 0 &&
          t.onlineSale === this.ticketBlock.onlineSale &&
          new Date(t.endDate) > new Date() &&
          parseInt(t.price) <= parseInt(this.ticketBlock.price)
      );
      if (ticketBlocks.length == 0) return null;
      return ticketBlocks.sort((a, b) => b.price - a.price)[0];
    },
  },
  mounted() {
    this.$root.$on("ticket-block-modal", this.open);
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    posSessions: {
      type: Array,
      default: () => [],
    },
    members: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
};
</script>
