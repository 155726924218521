<template>
  <v-dialog
    v-model="show"
    max-width="450"
    @click:outside="cancel"
    content-class="rounded-xl"
  >
    <v-card
      class="pa-4 px-6 d-flex flex-column align-center gap-8"
      rounded="xl"
    >
      <div
        v-if="ticketBlock?.BankRestriction"
        class="d-flex flex-column align-center px-6 gap-2"
      >
        <v-img
          :src="ticketBlock?.BankRestriction?.logo"
          width="100"
          height="100"
          contain
          class="rounded-lg"
        />
        <h5 v-if="ticketBlock?.BankRestriction" class="text-center mb-0">
          Esse ingresso é exclusivo para clientes
          <span class="font-weight-bold">{{
            ticketBlock?.BankRestriction?.name
          }}</span>
        </h5>
      </div>
      <h5 v-if="ticketBlock?.prePurchaseAlert" class="mb-0 px-2">
        {{ ticketBlock?.prePurchaseAlert?.title }}
      </h5>
      <div class="text-15 px-2">
        <template v-if="ticketBlock?.BankRestriction">
          <p class="mb-0 text-center">
            O ingresso {{ ticketBlock?.name }} só poderá ser comprado utilizando
            cartão de crédito {{ ticketBlock?.BankRestriction?.name }}.
          </p>
          <p class="mb-0 text-center text--secondary text-12">
            Alguns cartões podem não ser aceitos para a compra.
          </p>
        </template>
        <template v-if="ticketBlock?.prePurchaseAlert">
          <p
            v-for="line in (ticketBlock?.prePurchaseAlert?.message || '')
              .trim()
              .split('\n')"
            class="mb-2"
            style="min-height: 1.2em"
          >
            {{ line }}
          </p>
        </template>
      </div>
      <div class="d-flex justify-space-between w-full">
        <v-btn text @click="cancel">Cancelar</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="confirm">Continuar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      ticketBlock: null,
      resolve: null,
    };
  },
  methods: {
    async open(ticketBlock) {
      console.log(ticketBlock);
      return new Promise((resolve) => {
        this.ticketBlock = ticketBlock;
        this.show = true;
        this.resolve = resolve;
      });
    },
    close() {
      this.show = false;
      this.resolve = null;
      this.ticketBlock = null;
    },
    confirm() {
      this.resolve(true);
      this.close();
    },
    cancel() {
      this.resolve(false);
      this.close();
    },
  },
};
</script>
