<template>
  <base-card :loading="loading" class="rounded-lg">
    <div
      class="d-flex justify-center text--disabled align-center gap-2 pt-1 unselectable"
    >
      <small class="lh-1">
        {{ party?.name || "Carregando..." }}
      </small>
      <small class="lh-1 pl-1"> • </small>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            text
            class="text--disabled px-1 text-capitalize text-caption"
          >
            Detalhes
          </v-btn>
        </template>
        <v-card class="d-flex flex-column pa-1 rounded-lg">
          <v-btn
            small
            text
            class="justify-start"
            v-if="hasPermission([16, 64, 512])"
            :to="{
              name: 'admin.party.management',
              params: { partyId: party.id },
            }"
          >
            <v-icon left>mdi-cog</v-icon>
            Operacional
          </v-btn>
          <v-btn
            small
            text
            class="justify-start"
            v-if="hasPermission(131072)"
            :to="{
              name: 'admin.entrance.management',
              params: { partyId: party.id },
            }"
          >
            <v-icon left>mdi-qrcode</v-icon>
            Entrada
          </v-btn>
        </v-card>
      </v-menu>
    </div>
    <v-tabs v-model="tab" show-arrows class="rounded-t-lg" height="36px">
      <v-tab
        v-for="tab in !party ? [] : tabsFiltered"
        class="rounded-t-lg"
        :key="tab.component"
      >
        <v-icon left small>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" :touchless="tabsFiltered[tab]?.touchless">
      <v-tab-item v-for="item in !party ? [] : tabsFiltered" :key="item.name">
        <component
          :is="item.component"
          :party="party"
          :loading="loading"
          @refresh="getParty"
          class="px-4 pb-4"
        />
      </v-tab-item>
    </v-tabs-items>

    <tecnician-daily :party="party" />

    <!-- Navgation  -->
    <v-bottom-sheet
      v-model="navigationSheet"
      inset
      max-width="550px"
      :overlay-opacity="0.8"
      content-class="elevation-0"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          style="position: fixed; bottom: 76px; right: 16px; z-index: 5"
          float
          v-bind="attrs"
          v-on="on"
          color="primary"
        >
          <v-icon> mdi-dots-grid </v-icon>
        </v-btn>
      </template>
      <div class="mb-4 px-4 d-flex">
        <v-card class="pa-3 pb-1 rounded-lg">
          <v-row dense class="mx-0">
            <v-col
              v-for="tab in tabsNavigation"
              :key="tab.id"
              cols="4"
              sm="4"
              md="3"
            >
              <v-card
                @click="selectTab(tab.id)"
                outlined
                height="90px"
                elevation="0"
                class="pa-2"
              >
                <div
                  class="d-flex align-center justify-center flex-column h-full text-center lh-1 gap-2"
                >
                  <v-icon>{{ tab.icon }}</v-icon>
                  {{ tab.name }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="d-flex justify-center">
            <v-btn @click="navigationSheet = false" text small> Fechar </v-btn>
          </div>
        </v-card>
      </div>
    </v-bottom-sheet>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PartyDetails from "@/components/admin/party/PartyDetails";
import PartyPolicies from "@/components/admin/party/PartyPolicies";
import PartyAttractions from "@/components/admin/party/PartyAttractions.vue";
import PartyRating from "@/components/admin/party/PartyRating.vue";
import PartyGallery from "@/components/admin/party/PartyGallery.vue";
import PartyPrintConfigurator from "@/components/admin/party/PartyPrintConfigurator.vue";
import PartyPeriod from "@/components/admin/party/PartyPeriod.vue";
import PartyFaq from "@/components/admin/party/PartyFaq.vue";
import PartyMap from "@/components/admin/party/PartyMap.vue";
// import PartyProductUpsell from "@/components/admin/party/PartyProductUpsell.vue";
import PartyWhatsappGroup from "@/components/admin/party/PartyWhatsappGroup.vue";
import PartyTableMap from "@/components/admin/party/PartyTableMap.vue";
import PartyCoOrganizer from "@/components/admin/party/PartyCoOrganizer.vue";
import PartyBanners from "@/components/admin/party/detailsComponents/Banners.vue";
import PartyRiskOperations from "@/components/admin/party/detailsComponents/PartyRiskOperations.vue";
import TecnicianDaily from "@/components/global/tecnician/TecnicianDaily.vue";

export default {
  metaInfo() {
    return { title: `ℹ️ ${this.party?.name || "Carregando..."}` };
  },
  data: () => ({
    loading: true,
    tab: null,
    navigationSheet: false,
    tabs: [
      {
        id: "details",
        name: "Detalhes",
        component: "PartyDetails",
        permission: 2,
        icon: "mdi-information",
      },
      {
        id: "policies",
        name: "Políticas",
        component: "PartyPolicies",
        permission: 2,
        icon: "mdi-security",
      },
      {
        id: "attractions",
        name: "Atrações",
        component: "PartyAttractions",
        permission: 2,
        icon: "mdi-music",
      },
      {
        id: "period",
        name: "Períodos",
        component: "PartyPeriod",
        permission: 2,
        icon: "mdi-calendar-blank-multiple",
        noFree: true,
      },
      {
        id: "faq",
        name: "FAQ",
        component: "PartyFaq",
        permission: 2,
        touchless: true,
        icon: "mdi-frequently-asked-questions",
      },
      {
        id: "map",
        name: "Mapa",
        component: "PartyMap",
        permission: 2,
        icon: "mdi-map",
      },
      {
        id: "co-author",
        name: "Co-autor",
        component: "PartyCoOrganizer",
        permission: 2 + 8,
        touchless: true,
        icon: "mdi-account-supervisor",
        noFree: true,
      },
      {
        id: "table-map",
        name: "Mapa de Assentos",
        component: "PartyTableMap",
        permission: 2,
        touchless: true,
        icon: "mdi-table-chair",
        noFree: true,
      },
      {
        id: "gallery",
        name: "Galeria",
        component: "PartyGallery",
        permission: 2,
        icon: "mdi-image",
      },
      // {
      //   id: "product",
      //   name: "Produtos",
      //   component: "PartyProductUpsell",
      //   permission: 2 + 16384,
      //   icon: "mdi-cube-send",
      // },
      {
        id: "printConfigurator",
        name: "Configuração de Impressão",
        component: "PartyPrintConfigurator",
        permission: 2,
        icon: "mdi-printer-settings",
        noFree: true,
      },
      {
        id: "rating",
        name: "Avaliações",
        component: "PartyRating",
        permission: 2,
        icon: "mdi-star",
        noFree: true,
      },
      {
        id: "banners",
        name: "Banners",
        component: "PartyBanners",
        permission: 2,
        icon: "mdi-image-multiple",
      },
      {
        id: "wa-group",
        name: "Grupos do Whatsapp",
        component: "PartyWhatsappGroup",
        permission: 2,
        icon: "mdi-whatsapp",
        noFree: true,
      },
      {
        id: "risk",
        name: "Risco",
        component: "PartyRiskOperations",
        permission: 4,
        icon: "mdi-alert",
        hideFromNavigation: true,
        noFree: true,
      },
    ],
  }),
  methods: {
    ...mapActions("organization", { updateParty: "partyById" }),
    async getParty() {
      try {
        this.selectTab();
        this.loading = true;
        await this.updateParty(this.$route.params.partyId);
        this.loading = false;
        this.$root.$emit("setImgBg", this.party?.cover);
      } catch (error) {
        this.$router.push({ name: "admin.parties" });
      }
    },
    selectTab(tab) {
      if (tab) window.location.replace("#" + tab);
      if (this.$route.hash) {
        let hash = tab || this.$route.hash.replace("#", "");
        let index = this.tabsFiltered.findIndex((tab) => tab.id == hash);
        if (index >= 0) this.tab = index;
        this.navigationSheet = false;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("organization", { getPartyById: "partyById" }),
    ...mapGetters("auth", ["hasPermission"]),
    party() {
      return this.getPartyById(this.$route.params.partyId);
    },
    tabsFiltered() {
      return this.tabs.filter((tab) => {
        if (this.party.isFree && tab.noFree) return false;
        if (!tab.permission) return true;
        return this.hasPermission(tab.permission);
      });
    },
    tabsNavigation() {
      return this.tabsFiltered.filter((tab) => !tab.hideFromNavigation);
    },
  },
  watch: {
    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.tabsFiltered[val];
        if (tab) window.location.replace("#" + tab.id);
      },
      immediate: true,
    },
    "$route.hash"() {
      this.selectTab();
    },
    "$route.params.partyId"() {
      this.getParty();
    },
    "selectedOrganization.id"() {
      this.$router.push({
        name: "admin.parties",
      });
    },
  },
  components: {
    TecnicianDaily,
    PartyDetails,
    PartyAttractions,
    PartyRating,
    PartyGallery,
    PartyPrintConfigurator,
    PartyPeriod,
    PartyFaq,
    PartyPolicies,
    PartyMap,
    PartyTableMap,
    PartyCoOrganizer,
    PartyWhatsappGroup,
    // PartyProductUpsell,
    PartyBanners,
    PartyRiskOperations,
  },
  mounted() {
    this.getParty();
  },
};
</script>

<style></style>
