var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-column gap-2"},[_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":!!_vm.ticketBlock.prePurchaseAlert,"label":"Alerta de pré-compra","messages":[
            _vm.ticketBlock.prePurchaseAlert
              ? 'Alerta de pré-compra ativado'
              : 'Alerta de pré-compra desativado',
          ]},on:{"change":_vm.changePrePurchaseAlert}}),(!!_vm.ticketBlock.prePurchaseAlert)?_c('v-btn',{attrs:{"text":"","small":"","disabled":!_vm.ticketBlock.prePurchaseAlert.title ||
            !_vm.ticketBlock.prePurchaseAlert.message},on:{"click":_vm.openPrePurchaseAlertModal}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-eye")]),_vm._v(" Previsualizar ")],1):_vm._e()],1),(!!_vm.ticketBlock.prePurchaseAlert)?_c('div',{staticClass:"mt-4"},[_c('v-text-field',{attrs:{"label":"Título","outlined":"","dense":"","rules":[
            (v) => !!v || 'Título é obrigatório',
            (v) =>
              v.length <= 50 || 'Título deve ter no máximo 50 caracteres',
          ]},model:{value:(_vm.ticketBlock.prePurchaseAlert.title),callback:function ($$v) {_vm.$set(_vm.ticketBlock.prePurchaseAlert, "title", $$v)},expression:"ticketBlock.prePurchaseAlert.title"}}),_c('v-textarea',{attrs:{"label":"Mensagem","outlined":"","dense":"","hide-details":"auto","rules":[
            (v) => !!v || 'Mensagem é obrigatória',
            (v) =>
              v.length <= 200 || 'Mensagem deve ter no máximo 200 caracteres',
          ]},model:{value:(_vm.ticketBlock.prePurchaseAlert.message),callback:function ($$v) {_vm.$set(_vm.ticketBlock.prePurchaseAlert, "message", $$v)},expression:"ticketBlock.prePurchaseAlert.message"}})],1):_vm._e()]),_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":!!_vm.ticketBlock.bankRestrictionId,"label":"Restringir vendas ao banco","messages":[
          _vm.ticketBlock.prePurchaseAlert
            ? 'Alerta de pré-compra ativado'
            : 'Alerta de pré-compra desativado',
        ]},on:{"change":_vm.changeBankRestriction}}),(_vm.ticketBlock.bankRestrictionId !== null)?_c('bank-select',{attrs:{"label":"Restringir vendas ao banco:"},model:{value:(_vm.ticketBlock.bankRestrictionId),callback:function ($$v) {_vm.$set(_vm.ticketBlock, "bankRestrictionId", $$v)},expression:"ticketBlock.bankRestrictionId"}}):_vm._e()],1),_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":!!_vm.ticketBlock.useRequirement || _vm.ticketBlock.useRequirement === '',"label":"Requisito para uso do ingresso","messages":"Mensagem exibida ao usuário na compra e no ingresso"},on:{"change":_vm.changeTicketUseRequirement}}),(
          !!_vm.ticketBlock.useRequirement || _vm.ticketBlock.useRequirement === ''
        )?[_c('v-chip-group',{staticClass:"my-2",attrs:{"column":"","active-class":"primary--text"},model:{value:(_vm.ticketBlock.useRequirement),callback:function ($$v) {_vm.$set(_vm.ticketBlock, "useRequirement", $$v)},expression:"ticketBlock.useRequirement"}},_vm._l((_vm.ticketRequirementSugestions),function(suggestion){return _c('v-chip',{key:suggestion.name,attrs:{"value":suggestion.text,"small":""}},[_vm._v(" "+_vm._s(suggestion.name)+" ")])}),1),_c('v-text-field',{attrs:{"label":"Requisito para uso","hide-details":"auto","outlined":"","dense":"","counter":"40","maxlength":"40"},model:{value:(_vm.ticketBlock.useRequirement),callback:function ($$v) {_vm.$set(_vm.ticketBlock, "useRequirement", $$v)},expression:"ticketBlock.useRequirement"}})]:_vm._e()],2)],1),_c('pre-purchase-alert-modal',{ref:"prePurchaseAlertModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }